import axios from '../../node_modules/axios/index';
//confirm box to join call
var ConfirmBox = /** @class */ (function () {
    function ConfirmBox() {
    }
    ConfirmBox.prototype.show = function (pageHandler) {
        var c = confirm("Their is an active Call do you want to join?");
        if (c == true) {
            pageHandler.joinCall();
        }
        else {
            console.log("Canceled call");
        }
    };
    return ConfirmBox;
}());
var mainPageHandler = /** @class */ (function () {
    function mainPageHandler() {
        //we create a constant puller
        /*setInterval(this.pullCallStatus, 3000, this);*/
        //we set ticket input
        /*(<HTMLInputElement>document.getElementById('ticketID')).value =  "" + Math.floor(Math.random() * 1000 * 12);
        //we start call on this client side
        document.getElementById('startcall').addEventListener('click', e => {
            this.joinCall();
        }); */
    }
    //Time loop to pull call request, if the meetingID is not null we do an alert
    mainPageHandler.prototype.pullCallStatus = function (self) {
        var options = {
            method: 'GET',
            baseURL: mainPageHandler.baseurl,
            url: '/callcheck',
            params: { ticket: document.getElementById('ticketID').value }
        };
        axios.request(options).then(function (response) {
            var meetingID = response.data.meetingID;
            //meeting ID is not null, we show a confirm box and if we accept we go to the meeting
            console.log(meetingID);
            if (meetingID) {
                var msb = new ConfirmBox();
                msb.show(self);
            }
        }).catch(function (error) {
            console.log(error);
        });
    };
    //Sends a post request to join the call
    mainPageHandler.prototype.joinCall = function () {
        axios.post(mainPageHandler.baseurl + 'startcall', { ticket: document.getElementById('ticketID').value, device: 'browser' }, { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } })
            .then(function (response) {
            console.log('Should redirect');
            var meetingID = response.data;
            if (!meetingID)
                return;
            window.location.href = 'meetingV2?meetingID=' + meetingID + '&username=' + mainPageHandler.username;
        }).catch(function (error) {
            console.log(error);
        });
    };
    mainPageHandler.baseurl = "https://www.meiller-chime.com/";
    mainPageHandler.username = "BROWSERSUPPORT";
    return mainPageHandler;
}());
export { mainPageHandler };
window.addEventListener('load', function () {
    new mainPageHandler(); // we initialise on load
});
